import React from 'react'
import styles from './Certificate.module.css'
import { ethers } from 'ethers'

const Certificate = (props) => {
  const { image, styleRef, date, dimensions, type, gen, index, quantities, updateQuantity, sold, price } = props
  return (
    <div
      className={styles.container} style={{
        background: index % 2
          ? 'linear-gradient(180deg, #f5a9f4 0, #f5a9f4 30%, #f5a9f4 30.01%, #ffaa97 100%)'
          : 'linear-gradient(180deg, #d5eaf9 0, #d8ecfa 30%, #d8ecfa 30.01%, #bcddf1 100%)'
      }}
    >
      <div className={styles.wrap}>
        <img src={image} />
        <div className={styles.text}>
          <div>TYPE:  {type}</div>
          <div>STYLE REF:  {styleRef}</div>
          <div>DATE:  {date}</div>
          <div>GEN:  {gen}</div>
          <div>PRICE:  {ethers.utils.formatEther(price)} eth (includes shipping)</div>
          <div>DIMENSIONS:  {dimensions}</div>
          {sold * 1 !== 100 &&
            <div className={styles.inputWrap}>
              <div onClick={() => updateQuantity(index, -1)}>-</div>
              <input readOnly value={quantities[index]} disabled />
              <div onClick={() => updateQuantity(index, 1)}>+</div>
            </div>}
          {sold && sold * 1 !== 100 &&
            <div style={{ marginTop: 16 }}>{sold * 1} / 100 sold</div>}
          {sold && sold * 1 === 100 &&
            <div style={{ marginTop: 16, fontWeight: '600' }}>SOLD OUT</div>}
        </div>
      </div>
    </div>
  )
}

export default Certificate
