import React from 'react'
import Block from '../components/Block'
import Hero from '../components/Hero'
import diagram from '../assets/collectors_money.png'
import welcome from '../assets/1_welcome.jpeg'
import thumb from '../assets/2_how_it_works.jpeg'
import key from '../assets/key.png'
import balance from '../assets/balnce.png'
import thumb2 from '../assets/3_scratch.jpeg'
import thumb3 from '../assets/4_price.png'
import moneybox from '../assets/moneybox.png'
import handshake from '../assets/handshake.png'
import anti4 from '../assets/ANTI4.png'
import gropius from '../assets/Gropius-Bau_Logo.png'
import athens from '../assets/AB_logo_version_a.png'
import Footer from '../components/Footer'

const Home = () => {
  return (
    <div>
      <Hero />
      <Block
        showBg
        white
        title='Hello and welcome'
        body={`Welcome to the Crypto Certs, a dynamic funding model created by the team at Ed Fornieles Studios. The aim of the program is to rethink the financial mechanisms that sustain an artist's studio and to reshape them in innovative ways.

        The Crypto Certs have been created as a means to raise funds for the development and production of new artwork. As an accessible model with a transparent structure, that allows for open participation in the studio ecosystem, rewarding investment in production with a share of future profits.
        
        While the traditional financial model for artwork production relies on a small, centralized network of high-risk investors, the Crypto Certs diffuse risk across a wider, de-centralized network of support. In doing so the pressure is removed for artists and galleries to raise funds for production costs and allows them to develop artwork independently of market pressures. This leads to greater experimentation on the part of the artist and redirects the flow of capital towards the production, development and growth of future artworks.
        
        The Crypto Certs combine financial tools of the art world with the creativity of the financial sector.`}
        imageURL={diagram}
        thumbnail={welcome}
        videoURL='https://d2xc4uz53wxo0h.cloudfront.net/CERTIFICATE+PROGRAMME+PART+1.mp4'
        bg='#142026'
      />
      <Block
        white
        title='HOW IT WORKS'
        body={`The Crypto Certs employ ethereum blockchain technology to store and distribute value. Each certificate contains a key embedded under a scratch panel, which entitles the holder to a percentage of an ethereum fund generated through the sale of certificates and other artworks. As more sales occur, the ethereum fund grows. To gain access to the key and cash out on an investment, the holder must scratch the panel and damage the certificate.

        Once used, keys becomes defunct. Meaning that if you decide to withdraw your funds, timing is important, as their value depends on how much the ethereum currency is worth and how much is contained within the certificate fund at that given moment.`}
        imageURL={key}
        thumbnail={thumb}
        videoURL='https://d2xc4uz53wxo0h.cloudfront.net/CERTIFICATE+PROGRAMME+PART+2.mp4'
        bg='#6f6d67'
      />
      <Block
        title='The fund'
        body={`A combination of an artist print and a financial product, the certificate contains twofold value: that of an art object and that of a crypto fund. The holder must decide between conserving the value of the certificate as an artwork and damaging it to access the funds it contains.

        The structure of the Certificate Program has been designed to encourage gradual growth and stability. As each new generation is produced, the certificates increase in size and market value. These features reward early supporters with a low buy-in point for a fixed percentage of the studio fund and allow them to benefit from the higher contributions of later investors. At the same time, new investors are attracted by the value of more substantial art objects.`}
        imageURL={balance}
        thumbnail={thumb2}
        videoURL='https://d2xc4uz53wxo0h.cloudfront.net/CERTIFICATE+PROGRAMME+PART+3.mp4'
        bg='-webkit-radial-gradient(50.09% 50%, circle farthest-corner, #e9f5ff 0, #9bcce9 100%)'
      />
      <Block
        title='Rewards'
        body={`The structure of the Crypto Certs has been designed to encourage gradual growth and stability. As each new generation is produced, the sale value goes up at fixed points. This allows early supporters to enjoy a low price point for a fixed percentage of the studio fund, while benefiting from the higher contributions of later investors. The size and scale of certificates grow with each generation.

        This feature has been included to reduce the risk for new supporters, balancing the certificate’s higher cost against its value as an art object. In this way, new investors are attracted, while the stake for old investors increases against their smaller contribution.`}
        imageURL={moneybox}
        thumbnail={thumb3}
        videoURL='https://d2xc4uz53wxo0h.cloudfront.net/CERTIFICATE+PROGRAMME+PART+4.mp4'
        bg='#ebebeb'
      />
      <div style={{
        padding: '10% 10%',
        color: '#5e5e5e',
        background: '-webkit-radial-gradient(50.09% 50%, circle farthest-corner, #e9f5ff 0, #9bcce9 100%)',
        clear: 'both'
      }}
      >
        <div style={{ maxWidth: 1280, margin: 'auto' }}>
          <p style={{ fontSize: 19, whiteSpace: 'pre-line' }}>
            We invite you to take part in our studio, to share in our growth and to be part of an experimental platform that proposes an alternative, transparent and rewarding means of producing art.
            <br /><br />
            The Crypto Certs are available from our store as well as participating galleries, dealers and institutions.
            <br /><br />
            The capital value of units in the fund can fluctuate and the price of units can go down as well as up and is not guaranteed. This is a speculative art product and the prices may fluctuate widely.
          </p>
          <img src={handshake} style={{ marginTop: 20, maxWidth: '100%' }} />
          <div style={{ fontFamily: 'Andale Mono', fontSize: 26, paddingBottom: 20, fontWeight: 600 }}>PARTNERS</div>
          <div style={{ width: 500, maxWidth: '100%' }}>
            <img src={anti4} style={{ marginRight: 10, maxWidth: '100%' }} />
            <img src={gropius} style={{ maxWidth: '100%' }} />
            <img src={athens} style={{ maxWidth: '100%', marginTop: 8 }} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Home
