import keccak256 from 'keccak256'
import addresses from './studioCertPublicAddresses'
import { MerkleTree } from 'merkletreejs'
global.Buffer = require('buffer').Buffer

export const generateMerkleProof = (address) => {
  const leafNodes = []
  addresses.forEach((addy, index) => {
    leafNodes.push(keccak256(addy.address))
  })

  const merkleTree = new MerkleTree(
    leafNodes,
    keccak256,
    { sortPairs: true }
  )

  return merkleTree.getHexProof(keccak256(address))
}
