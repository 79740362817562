import React from 'react'
import styles from './Footer.module.css'
import instagram from '../assets/instagram.svg'
import facebook from '../assets/facebook.svg'
import mail from '../assets/mail.svg'

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrap}>
        <div>Unit 4</div>
        <div>88 Mile End Rd</div>
        <div>London</div>
        <div>E2 4UN</div>
        <ul>
          <li>
            <a href='https://www.instagram.com/ed_fornieles_studios/'>
              <img src={instagram} />
            </a>
          </li>
          <li>
            <a href='https://www.facebook.com/edfornielesstudios'>
              <img src={facebook} />
            </a>
          </li>
          <li>
            <a href='mailto:help@edfornielesstudios.com'>
              <img src={mail} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Footer
