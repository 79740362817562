import React, { Component } from 'react'
import Certificate from '../components/Certificate'
import Footer from '../components/Footer'
import Hero from '../components/Hero'
import one from '../assets/1.jpeg'
import two from '../assets/2.jpeg'
import three from '../assets/3.jpeg'
import four from '../assets/4.jpeg'
import five from '../assets/5.jpeg'
import Buy from '../components/Buy'
import { ethers, utils } from 'ethers'
import { contractFactory } from '../utilities/contractFactory'
import styles from './Store.module.css'

class Store extends Component {
  state = {
    quantities: [0,0,0,0,0],
    editionsSold: [],
    submitted: false,
    success: false,
    address: '',
    email: '',
    transactionHash: '',
    price: '0'
  }
  componentDidMount () {
    this.getAvailable()
  }
  getAvailable = async () => {
    let editionsSold = []
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let contract = await contractFactory()
    for (let i = 0; i < 5; i++) {
      let sold = await contract.editionsSold(i)
      editionsSold[i] = sold
    }
    // fetch price as well
    let price = await contract.price()
    this.setState({
      editionsSold: editionsSold,
      price: price
    })
  }
  updateQuantity = (index, quantity) => {
    let newQuants = [ ...this.state.quantities ]
    newQuants[index] = newQuants[index] + quantity
    if (newQuants[index] < 0) return
    this.setState({
      quantities: newQuants
    })
  }
  purchase = async () => {
    this.setState({
      error: ''
    })
    await window.ethereum.request({ method: 'eth_requestAccounts' })
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    var signer = provider.getSigner();
    let contract = await contractFactory(signer)
    try {
      let wearClothes = await contract.purchase(this.state.quantities, {
        value: this.state.price.mul(this.sumQuantites())
      });
      this.setState({
        submitted: true
      })
      let receipt = await wearClothes.wait()
      this.setState({
        transactionHash: receipt.transactionHash,
        success: true
      })
    } catch (err) {
      if (err.toString().indexOf('Not enough supply') > -1) {
        this.setState({
          error: 'Not enough supply for this purchase.'
        })
      } else if (err.toString().indexOf('insufficient funds') > -1) {
        this.setState({
          error: 'Insufficient funds.'
        })
      } else {
        console.log(err)
        this.setState({
          error: 'Error'
        })
      }
    }
  }
  sumQuantites = () => {
    let sum = 0
    for (let i = 0; i < this.state.quantities.length; i++) {
      sum += this.state.quantities[i]
    }
    return sum
  }
  onChange = (e, type) => {
    this.setState({
      [type]: e.target.value
    })
    console.log(this.state)
  }
  submitAddress = async () => {
    try {
      await fetch(
        'https://hooks.zapier.com/hooks/catch/10011094/b5ljatw/', {
          method: 'POST',
          body: JSON.stringify({
            transaction: this.state.transactionHash,
            address: this.state.address,
            email: this.state.email,
            quantities: {
              firstEdition: this.state.quantities[0],
              secondEdition: this.state.quantities[1],
              thirdEdition: this.state.quantities[2],
              fourthEdition: this.state.quantities[3],
              fifthEdition: this.state.quantities[4]
            }
          })
        }
      )
      this.setState({
        submitted: false,
        success: false,
        quantities: [0,0,0,0,0],
        transactionHash: ''
      })
      window.alert('Thank you! We received your order and shipping address. Contact us if you have any questions.')
    } catch (err) {
      console.log(err)
      window.alert('Submission failed, please DM @jakeywan on twitter and screenshot this error: ', err)
    }
    
    
  }
  render () {
    return (
      <div>
        <Hero />
        <Certificate
          price={this.state.price}
          type='STUDIO CERTIFICATE'
          styleRef='EF001'
          date='JULY/18'
          gen='1.0'
          dimensions='31 x 41 CM'
          image={one}
          index={0}
          updateQuantity={this.updateQuantity}
          quantities={this.state.quantities}
          sold={this.state.editionsSold[0]}
        />
        <Certificate
          price={this.state.price}
          type='STUDIO CERTIFICATE'
          styleRef='EF002'
          date='JULY/18'
          gen='1.0'
          dimensions='31 x 41 CM'
          image={two}
          index={1}
          updateQuantity={this.updateQuantity}
          quantities={this.state.quantities}
          sold={this.state.editionsSold[1]}
        />
        <Certificate
          price={this.state.price}
          type='STUDIO CERTIFICATE'
          styleRef='EF003'
          date='JULY/18'
          gen='1.0'
          dimensions='31 x 41 CM'
          image={three}
          index={2}
          updateQuantity={this.updateQuantity}
          quantities={this.state.quantities}
          sold={this.state.editionsSold[2]}
        />
        <Certificate
          price={this.state.price}
          type='STUDIO CERTIFICATE'
          styleRef='EF004'
          date='JULY/18'
          gen='1.0'
          dimensions='31 x 41 CM'
          image={four}
          index={3}
          updateQuantity={this.updateQuantity}
          quantities={this.state.quantities}
          sold={this.state.editionsSold[3]}
        />
        <Certificate
          price={this.state.price}
          type='ATHENS BIENNALE OFFICAL CERTIFICATES'
          styleRef='EF005'
          date='JULY/18'
          gen='1.0'
          dimensions='31 x 41 CM'
          image={five}
          index={4}
          updateQuantity={this.updateQuantity}
          quantities={this.state.quantities}
          sold={this.state.editionsSold[4]}
        />
        {this.sumQuantites() > 0 &&
          <Buy price={this.state.price} sum={this.sumQuantites()} onClick={this.purchase} error={this.state.error} />
        }
        {this.state.submitted &&
          <div className={styles.popup}>
            {!this.state.success &&
              <div>Processing transaction, please don't leave or refresh the page...</div>}
            {this.state.success &&
              <div>
                <div style={{ marginBottom: 32 }}>Transaction succeeded, please add your shipping info:</div>
                <input onChange={(e) => this.onChange(e, 'address')} value={this.state.address} placeholder='Shipping address...' />
                <input onChange={(e) => this.onChange(e, 'email')} value={this.state.email} placeholder='Contact email in case of issues...' />
                <div className={styles.button} onClick={this.submitAddress}>Submit</div>
                <div style={{ fontSize: 14 }}>If you have any issues or questions, email us at help@edfornielesstudios.com.</div>
              </div>
            }
          </div>
        }
        <Footer />
      </div>
    )
  }
}

export default Store
