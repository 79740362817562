import { Link } from 'react-router-dom'
import styles from './Nav.module.css'
import React, { Component } from 'react'

class Nav extends Component {
  state = {
    address: ''
  }
  componentDidMount () {
    // Get the connected account & setup event handler for account change
    if (!window.ethereum) return // prevents error thrown on mobile
    window.ethereum.on('accountsChanged', this.accountChangeHandler)

    setTimeout(() => {
      let address = window.ethereum.selectedAddress
      if (address) {
        this.setState({
          address: address
        })
      }
    }, 500)
  }
  accountChangeHandler = (accounts) => {
    this.setState({ connectedAccount: accounts[0] })
    if (accounts[0]) {
      this.setState({
        address: accounts[0]
      })
    }
  }
  connectWallet = async () => {
    console.log('connect')
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
    this.setState({
      address: accounts[0]
    })
  }
  render () {
    return (
      <div className={styles.container}>
        <ul>
          <li>
            <Link to='/'>About</Link>
          </li>
          <li>
            <Link to='/store'>Store</Link>
          </li>
          <li>
            <Link to='/contact'>Contact</Link>
          </li>
          <li>
            <Link to='/balance'>Balance</Link>
          </li>
          <li>
            <div className={styles.connect} onClick={this.connectWallet}>
              {this.state.address ? '0x...' + this.state.address.substring(this.state.address.length - 4) : 'Connect'}
            </div>
          </li>
        </ul>
      </div>
  )
  }
}

export default Nav