import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styles from './Popup.module.css'

class Popup extends Component {
  state = {
    show: true
  }
  dismiss = () => {
    this.setState({
      show: false
    })
    const body = document.getElementsByTagName('body')
    body[0].style.overflowY = ''
  }
  componentDidMount () {
    const body = document.getElementsByTagName('body')
    body[0].style.overflowY = 'hidden'
  }
  componentWillUnmount () {
    const body = document.getElementsByTagName('body')
    body[0].style.overflowY = ''
  }
  render () {
    if (!this.state.show) return null
    return (
      <>
        <div className={styles.overlay} />
        <div className={styles.container}>
          <div className={styles.title}>
            Welcome to Ed Fornieles Studios and the Crytpo Certs, an experimental blockchain crowdfunding model launched in 2018, to raise production costs for digital and immaterial work that has often proved difficult to fund.
          </div>
          <p>
            The Crypto Certs were created to fund the development and production of new artwork. As an accessible model with a transparent structure, they allow for open participation in the studio ecosystem. The website has been kept largely as it was in 2018, as a record of the idea and what it was attempting to achieve, although we have made some updates.
          </p>
          <div>The basics:</div>
          <p>
            Each certificate contains a private key embedded under a scratch panel. Collectors can “burn” their certificate at any time by scratching to reveal the private key, redeeming a proportional share of the funds in the contract.
          </p>
          <p>
            This gives the certificates both physical and on chain properties in a way that remains quite distinctive today. You might call them fungible non tokens (FNTs). The designs draw inspiration from early stock certificates, particularly <a href='https://www.gannett-cdn.com/-mm-/541529cffd659da8c05f7a674baaf0967c850de1/c=0-373-2912-2019/local/-/media/USATODAY/WiresImages/2013/10/09/a3ad390f0c82b5223f0f6a706700a132.jpg' target='_blank'>Disney stocks</a>, and will often commemorate specific events or projects. If you look closely, you'll see <a href='https://finiliar.com' target='_blank'>finiliar</a> represented on them.
          </p>
          <div>The updates:</div>
          <p>
            The 2018 contract was deployed before front-running was a well-known security issue, so it needed to be updated. We moved purchasing and edition counts on chain, and used a Merkle tree and cryptographic signatures to preempt front-running. The new contract points to the original to maintain chain of provenance.
          </p>
          <div>Purchase and redemption:</div>
          <p>
            Certificates will be released in editions. Five original 2018 editions are still available, capped at 100 certificates each. When these editions are sold out, new editions will be released at increasing prices. 50% of each sale is automatically retained in the contract for redemption.
          </p>
          <p>
            Collectors can redeem their certificates at any time. Payout rates are proportional to the number of circulating certificates: payout = contractBalance / circulatingSupply. The team has accepted partial payment in certificates, and certificates were sold at various galleries in the intervening years, which will add dilution to the pool.
          </p>
          <div>A word of caution:</div>
          <p>
            <b>This project was undertaken in an optimistic but experimental spirit. It is not without risks, and carries no guarantees. Physical keys can be lost or destroyed, and smart contracts always introduce ambient risk factors. We'd love for you to participate, but please only do so in the same spirit of experimentation, and not with an expectation of profit.</b>
          </p>
          <div className={styles.button} onClick={this.dismiss}>
            Continue to site >>
          </div>
        </div>
      </>
    )
  }
}

export default Popup
