import React from 'react'
import Footer from '../components/Footer'
import Hero from '../components/Hero'

const Contact = () => {
  return (
    <div>
      <Hero />
      <div style={{
        maxWidth: 1280,
        margin: 'auto',
        padding: '10%'
      }}
      >
        <div style={{ fontFamily: 'Andale Mono', fontSize: 27 }}>CONTACT</div>

        <div style={{ fontFamily: 'Myriad Pro', fontSize: 19 }}>
          <div style={{ marginTop: 30 }}>General Enquiries:</div>

          <div>email: help@edfornielesstudios.com</div>

          <div style={{ marginTop: 30 }}>Problems with an order or using our site?</div>

          <div>email: help@edfornielesstudios.com</div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Contact
