import React from 'react'
import styles from './Block.module.css'
import background from '../assets/header-element__bottom.png'

const BlockOne = (props) => {
  const { thumbnail, videoURL, imageURL, body, title, bg, white, showBg } = props
  return (
    <div className={styles.container} style={{ background: bg, color: white ? '#f7f4f9' : '#5e5e5e' }}>
      <div className={styles.wrap}>
        <h2>{title}</h2>
        <video controls='controls' poster={thumbnail}>
          <source src={videoURL} type='video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;' />
          <a href={videoURL}>Download the video</a>.
        </video>
        <p>
          {body}
        </p>
        <img src={imageURL} />
      </div>
      {showBg && <img src={background} className={styles.bgImg} style={{ marginTop: 0 }} />}
    </div>
  )
}

export default BlockOne
