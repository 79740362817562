import Nav from './components/Nav'
import { Routes, Route } from 'react-router-dom'
import Home from './containers/Home'
import Store from './containers/Store'
import Contact from './containers/Contact'
import Balance from './containers/Balance'
import Popup from './components/Popup'

function App () {
  return (
    <div>
      <Nav />
      <Popup />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/store' element={<Store />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/balance' element={<Balance />} />
      </Routes>
    </div>
  )
}

export default App
