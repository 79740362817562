import React from 'react'
import styles from './Hero.module.css'
import logo from '../assets/logo.png'
import bg from '../assets/header-element__top.png'

const Hero = () => {
  let showMinimal
  if (window.location.pathname !== '/') {
    showMinimal = true
  }
  return (
    <div
      className={styles.container}
      style={{
        height: showMinimal && 0,
        paddingTop: showMinimal && 150
      }}
    >
      <img src={logo} />
      <h1>Crypto Certs</h1>
      <div>by Ed Fornieles Studios</div>
      <img src={bg} style={{ display: showMinimal && 'none' }} className={styles.bgImage} />
    </div>
  )
}

export default Hero
