import Footer from '../components/Footer'
import Hero from '../components/Hero'
import React, { Component } from 'react'
import styles from './Balance.module.css'
import key from '../assets/key.png'
import balance from '../assets/balance-2.png'
import { generateMerkleProof } from '../utilities/generateMerkleProof'
import { ethers } from 'ethers'
import { contractFactory } from '../utilities/contractFactory'
import keccak256 from 'keccak256'

class Balance extends Component {
  state = {
    balanceAddress: '',
    balance: '',
    privateKey: '',
    userAddress: '',
    publicKey: ''
  }
  checkBalance = async () => {
    const proof = generateMerkleProof(this.state.balanceAddress)
    let contract = await contractFactory()
    // validate merkle proof
    const valid = await contract.checkMerkleProof(proof, this.state.balanceAddress)
    // validate not used
    const used = await contract.hasClaimed(this.state.balanceAddress)
    let payout
    if (valid && !used) {
      payout = await contract.currentPayout()
      payout = ethers.utils.formatEther(payout) * 1
      this.setState({
        balance: payout.toFixed(3)
      })
    } else {
      this.setState({
        balance: 'Invalid or used certificate'
      })
    }
  }
  cashOut = async () => {
    let wallet = new ethers.Wallet(this.state.privateKey)
    const proof = generateMerkleProof(this.state.publicKey)
    // address to receive funds
    const hashOfAddress = '0x' + keccak256(this.state.userAddress).toString('hex')
    let signedHash = await wallet.signMessage(ethers.utils.arrayify(hashOfAddress));

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    var signer = provider.getSigner();
    let contract = await contractFactory(signer)
    try {
      await contract.redeem(proof, signedHash, this.state.userAddress)
    } catch (err) {
      console.log(err)
      this.setState({
        error: 'Error. Certificate may be invalid or already claimed.'
      })
    }
    
  }
  update = (e, type) => {
    this.setState({
      [type]: e.target.value
    })
  }
  render () {
    return (
      <div>
        <Hero />
        <div className={styles.containerOne}>
          <div className={styles.wrap}>
            <div className={styles.title}>CHECK CERTIFICATE BALANCE:</div>
            <div className={styles.inputWrap}>
              <img src={balance} />
            </div>
            <input
              onChange={(e) => this.update(e, 'balanceAddress')}
              className={styles.input}
              value={this.state.balanceAddress}
              placeholder='Cerfiticate address (e.g. 0x...)' />
            <div onClick={this.checkBalance} className={styles.button} style={{ background: '#4CAF50' }}>Check Address</div>
            {this.state.balance && <div style={{ fontSize: 19, marginTop: 10 }}>Current balance: {this.state.balance} eth</div>}
          </div>
        </div>
        <div className={styles.containerTwo}>
          <div className={styles.wrap}>
            <div className={styles.title}>CASH OUT CERTIFICATE:</div>
            <div className={styles.inputWrap}>
              <img src={key} style={{ height: 35 }} />
            </div>
            <input onChange={(e) => this.update(e, 'publicKey')} value={this.state.publicKey} className={styles.input} placeholder='Certificate public key...' />
            <input onChange={(e) => this.update(e, 'privateKey')} value={this.state.privateKey} className={styles.input} placeholder='Certificate private key...' />
            <input onChange={(e) => this.update(e, 'userAddress')} value={this.state.userAddress} className={styles.input} placeholder='Address to receive funds (your wallet)...' />
            <div onClick={this.cashOut} className={styles.button}>Cash Me Out</div>
            {this.state.error && <div style={{ fontSize: 19, marginTop: 10, color: 'red' }}>{this.state.error}</div>}
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Balance
