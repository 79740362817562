import React from 'react'
import styles from './Buy.module.css'
import { ethers } from 'ethers'

const Buy = (props) => {
  const { sum, onClick, error, price } = props
  return (
    <div className={styles.container} onClick={onClick}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', textAlign: 'left' }}>
        {sum} certificates
        {error && <div style={{ color: 'red' }}>{error}</div>}
      </div>
      <div className={styles.button}>
        Purchase {ethers.utils.formatEther(price.mul(sum))} eth
      </div>
    </div>
  )
}

export default Buy
